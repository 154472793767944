<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <c-dept type="search" label="LBLDEPT" name="deptCd" :isFirstValue="false" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
          <c-field label="예약자" name="deptCd" :isFirstValue="false" v-model="searchParam.reserveUserId" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="종합검진 예약자 목록"
          tableId="table"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :columnSetting="false"
        >
          <!-- 버튼 영역 -->
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkFlag'">
              <q-chip
                v-if="props.row.checkFlag"
                outline square
                :color="setTagColor(props.row.checkFlag)"
                :class="props.row.checkFlag ==='2' ? 'blinking' : null"
                text-color="white">
                {{setTagName(props.row.checkFlag)}}
              </q-chip>
            </template>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'check-up-reserve-status',
  props: {
    data: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        checkupStartDate: '',
        checkupEndDate: '',
        regUserId: '',
        chgUserId: '',
        checkupResultModelList: [],
        deleteCheckupResultModelList: [],

        // prototype
        hospitalId: '',
        hospitalLocation: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'reserveDeptName',
            field: 'reserveDeptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'reserveUserName',
            field: 'reserveUserName',
            label: '예약자',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'reserveTime',
            field: 'reserveTime',
            label: '예약시간',
            style: 'width:90px',
            align: 'center',
            sortable: false,
          },
          {
            name: 'reserveName',
            field: 'reserveName',
            label: 'LBLREMARK',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          // {
          //   name: 'checkFlag',
          //   field: 'checkFlag',
          //   label: '검진/미검진',
          //   align: 'center',
          //   type: 'custom',
          //   style: 'width: 90px',
          //   sortable: true,
          // },
        ],
        data: [],
        merge: [
          // { index: 0, colName: 'mergeGroup' },
        ]
      },
      searchParam: {
        plantCd: '',
        deptCd: '',
        reserveUserId: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
      colorItems: [
        { stepperMstCd: '1', stepperMstNm: '종합검진 받음', colorClass: 'blue' },
        { stepperMstCd: '2', stepperMstNm: '검진 미수검자', colorClass: 'red' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'data'() {
      this.getList();
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.reserve.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        heaCheckupPlanId: this.data.heaCheckupPlanId,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data
      },);
    },
    setTagColor(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.attrVal1 || color.colorClass : '';
      } else {
        return ''
      }
    },
    setTagName(data) {
      if (this.colorItems) {
        let color = this.$_.find(this.colorItems, { code: data }) || this.$_.find(this.colorItems, { stepperMstCd: data })
        return color ? color.codeName || color.stepperMstNm : '';
      } else {
        return ''
      }
    },
  }
};
</script>
